import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { Slide } from 'react-awesome-reveal';

const Section = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <React.Fragment>
      {/* <!-- START HOME --> */}
      <section className='bg-home' id='home'>
        <div className='bg-overlay'></div>
        <Container>
          <Slide>
            <Row className='align-items-center'>
              <Col lg={6}>
                <div className='home-content me-lg-5'>
                  <h6 className='sub-title mb-3 text-white'>
                    AT <span className='text-primary'>EQUINOX TINTING</span>
                  </h6>
                  <h3 className='mb-4 text-white'>
                    We are passionate about what we do, we believe our succuss
                    has to do with our passion, dedication and customer
                    satisfaction.{' '}
                  </h3>
                  {/* <p className='text-white-50 fs-17'>
                    Port Saint Lucie's #1 one stop shop for top notch customer
                    service and tints.
                  </p> */}
                  <div className='mt-4'>
                    <Link
                      to='tel:+17723803272'
                      className='btn btn-outline-white mt-2'
                    >
                      Call Us Now
                    </Link>{' '}
                    {/* <Link
                      to='/#services'
                      className='btn btn-outline-white mt-2 ms-md-1'
                    >
                      Learn More
                    </Link> */}
                  </div>
                </div>
              </Col>
              {/* <Col lg={6}>
                <div className='home-dashboard mt-4 mt-lg-0'>
                  <img src={img6} alt='' className='img-fluid' />

                  <div className='play-btn'>
                    <Link
                      to='#'
                      onClick={() => setOpen(true)}
                      className='image-popup'
                    >
                      <i className='mdi mdi-motion-play'></i>
                    </Link>
                  </div>
                </div>
              </Col> */}
            </Row>
          </Slide>
        </Container>
      </section>
      {/* <!-- END HOME --> */}
      {/* <!-- START SHAPE --> */}
      <div className='position-relative'>
        <div className='shape'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            version='1.1'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            width='1440'
            height='150'
            preserveAspectRatio='none'
            viewBox='0 0 1440 150'
          >
            <g mask='url("#SvgjsMask1022")' fill='none'>
              <path
                d='M 0,58 C 144,73 432,131.8 720,133 C 1008,134.2 1296,77.8 1440,64L1440 250L0 250z'
                fill='#13a7a8'
              ></path>
            </g>
            <defs>
              <mask id='SvgjsMask1022'>
                <rect width='1440' height='250' fill='#ffffff'></rect>
              </mask>
            </defs>
          </svg>
        </div>
      </div>
      {/* <!-- END SHAPE --> */}
      <ModalVideo
        videoId='MD9WlEv4cQM'
        autoplay
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />
    </React.Fragment>
  );
};

export default Section;
