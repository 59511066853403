import React from 'react';
import { Col, Container, Row, Form, Label, Input, FormGroup } from 'reactstrap';
const Card = (props) => {
  return (
    <React.Fragment>
      <Col lg={3} md={6}>
        <div className='contact-info text-center mt-5'>
          <div className='icon'>
            <i className={props.iconClass}></i>
          </div>
          <div className='mt-4 pt-2'>
            <h6 className='fs-17'>{props.title}</h6>
            {props.isChildItem.map((item, key) => (
              <p style={{color: 'white'}} className='mb-0' key={key}>
                {item}
              </p>
            ))}
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

// Contact Data

const ContactData = [
  {
    title: 'Main Office',
    iconClass: 'mdi mdi-map-marker text-primary h4',
    isChildItem: [
      '500 NW University Blvd Unit 101',
      'Port St. Lucie, FL 34986',
    ],
  },
  {
    title: 'Phone & Email',
    iconClass: 'mdi mdi-phone text-primary h4',
    isChildItem: ['Phone: (772) 380-3272', ' Email: equinoxtinting@gmail.com'],
  },
  // {
  //   title: 'Contact',
  //   iconClass: 'mdi mdi-email text-primary h4',
  //   isChildItem: ['www.equinoxtinting.com', 'tito@equinoxtinting.com'],
  // },
  {
    title: 'Working Hours',
    iconClass: 'mdi mdi-calendar-clock text-primary h4',
    isChildItem: ['Monday-Saturday: 9:00am-5:00pm', 'Sunday: CLOSED'],
  },
];

const location = {
  address: '500 NW University Blvd, Port St. Lucie, FL 34986',
  lat: 27.32532318741786,
  lng: -80.39852465767129,
};

const Contact = () => {
  return (
    <React.Fragment>
      <section className='section' id='contact'>
        <Container>
          <Row className='justify-content-center'>
            <Col lg={7}>
              <div className='text-center mb-4'>
                {/* <p className='text-uppercase text-light mb-2'>Contact</p> */}
                <h1 className='text-uppercase'>CONTACT US</h1>
                {/* <div className='title-border mt-3'></div>
                <p className='title-desc text-light2 mt-3'>
                  We craft digital, graphic and dimensional thinking, to create
                  category leading brand experiences that have meaning and add a
                  value.
                </p> */}
                <iframe
                  width='100%'
                  height='400'
                  frameborder='0'
                  scrolling='no'
                  marginheight='0'
                  marginwidth='0'
                  src='https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=500%20NW%20UNIVERSITY%20BLVD%20UNIT%20101,%20Port%20Saint%20Lucie,%20FL%2034986+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed'
                ></iframe>
              </div>
            </Col>
          </Row>

          {/*  */}

          <Row className='justify-content-center'>
            {/* Contact Component Start */}

            {ContactData.map((value, idx) => (
              <Card
                key={idx}
                title={value.title}
                iconClass={value.iconClass}
                isChildItem={value.isChildItem}
              />
            ))}
            {/* Contact Component End */}
          </Row>

          {/* <Row className='mt-5 pt-2 justify-content-center'>
            <Col lg={9}>
              <div className='custom-form'>
                <Form action='#' name='myForm'>
                  <p id='error-msg'></p>
                  <Row>
                    <Col lg={12}>
                      <FormGroup>
                        <div className='mb-3'>
                          <Label for='name' className='form-label'>
                            Name* :
                          </Label>
                          <Input
                            name='name'
                            id='name'
                            type='text'
                            className='form-control'
                            placeholder='Enter your name'
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <div className='mb-3'>
                          <Label for='email' className='form-label'>
                            Email address* :
                          </Label>
                          <Input
                            type='email'
                            className='form-control'
                            name='email'
                            id='email'
                            placeholder='Enter your email'
                          />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col lg={6}>
                      <FormGroup>
                        <div className='mb-3'>
                          <Label for='number' className='form-label'>
                            Phone number* :
                          </Label>
                          <Input
                            type='number'
                            className='form-control'
                            name='number'
                            id='number'
                            placeholder='Enter your number'
                          />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col lg={12}>
                      <FormGroup>
                        <div className='mb-3'>
                          <Label for='comments' className='form-label'>
                            Comments :
                          </Label>
                          <Input
                            type='textarea'
                            className='form-control'
                            placeholder='Leave a comment here'
                            name='comments'
                            id='comments'
                            style={{ height: '100px' }}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12}>
                      <FormGroup>
                        <div className='mt-3 text-end'>
                          <input
                            type='submit'
                            id='submit'
                            name='send'
                            className='submitBnt btn btn-primary'
                            value='Send Message'
                          />
                          <div id='simple-msg'></div>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row> */}
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Contact;
