import React from 'react';
import logolight from '../assets/images/equinox-logo.png';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

const ListCard = (props) => {
  return (
    <React.Fragment>
      <div className={props.isList ? 'col-lg-3 col-md-6' : 'col-lg-2 col-md-6'}>
        <div className={props.isShow ? 'mt-4 ps-0 ps-lg-5' : 'mt-4'}>
          <h6 className='text-white text-uppercase fs-16'>{props.title}</h6>
          <ul className='list-unstyled footer-link mt-3 mb-0'>
            {props.isChildList.map((Item, key) => (
              <li key={key}>
                <Link to='/#' alt='work'>
                  {Item.subList}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

const ListData = [
  {
    title: 'Resources',
    isList: true,
    isShow: true,
    isChildList: [
      { id: 1, subList: 'Company History' },
      { id: 2, subList: 'Documentation' },
      { id: 3, subList: 'Term & Service' },
      { id: 4, subList: 'Components' },
    ],
  },
  {
    title: 'Help',
    isList: false,
    isShow: false,
    isChildList: [
      { id: 1, subList: 'Sign Up' },
      { id: 2, subList: 'Login' },
      { id: 3, subList: 'Careers' },
      { id: 4, subList: 'Privacy Policy' },
    ],
  },
];

const ListIconData = [
  {
    iconClass: 'mdi mdi-facebook',
    link: 'https://www.facebook.com/profile.php?id=61550664565050',
  },
  {
    iconClass: 'mdi mdi-instagram',
    link: 'https://www.instagram.com/eqxtinting/',
  },
  // {
  //   iconClass: 'mdi mdi-pinterest',
  // },
  // {
  //   iconClass: 'mdi mdi-twitter',
  // },
];

const Footer = () => {
  return (
    <React.Fragment>
      <footer className='bg-footer'>
        <Container>
          <Row>
            {/* <Col lg={3}> */}
            <div style={{ textAlign: 'center' }} className='mt-4'>
              <Link to='/'>
                <img src={logolight} alt='' height='50' />
              </Link>
              {/* <p className='text-white-50 mt-3 pt-2 mb-0 '>
                  It is a long established fact that a reader will be of a page
                  reader will be of at its layout.
                </p> */}
              <div className='mt-4'>
                <ul className='list-inline footer-social'>
                  {/* Footer Icon Component Start */}
                  {ListIconData.map((item, key) => (
                    <li className='list-inline-item' key={key}>
                      <Link to={item.link}>
                        <i className={item.iconClass}></i>
                      </Link>
                    </li>
                  ))}
                  {/* Footer Icon Component End */}
                </ul>
              </div>
            </div>
            {/* </Col> */}

            {/* Footer List Start*/}

            {/* {ListData.map((item, key) => (
              <ListCard
                key={key}
                isList={item.isList}
                isShow={item.isShow}
                title={item.title}
                isChildList={item.isChildList}
              />
            ))} */}
            {/* Footer List End*/}

            {/* <Col lg={4}>
              <div className='mt-4'>
                <h6 className='text-white text-uppercase fs-16'>Latest news</h6>
                <div className='mt-3 mb-0'>
                  <div className='d-flex'>
                    <i className='mdi mdi-twitter text-white-50 float-start'></i>
                    <div className='flex-grow-1'>
                      <p className='text-white-50 ps-3'>
                        Pleasures have to repudiated annoyances accepted
                        therefore always holds chooses enjoy a pleasure
                        consequences.
                      </p>
                    </div>
                  </div>
                  <div className='mt-2'>
                    <div className='d-flex'>
                      <i className='mdi mdi-twitter text-white-50 float-start'></i>
                      <div className='flex-grow-1'>
                        <p className='text-white-50 ps-3'>
                          Greater pleasures el esndures pains avoid welcomed
                          avoided pariatu
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col> */}
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
