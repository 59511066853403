import React from 'react';

import BackToTop from '../components/BackToTop';
import Contact from '../components/Contact';
import CTA from '../components/CTA';
import Footer from '../Layout/Footer';
import FooterAlt from '../Layout/FooterAlt';
import Portfolio from '../components/Portfolio';
import Services from '../components/Services';

const Layout = (props) => {
  return (
    <React.Fragment>
      {props.children}
      <Services />
      {/* <About /> */}
      {/* <Process />
      <Counter /> */}
      {/* <Testimonial /> */}
      {/* <Team /> */}
      <Portfolio />
      {/* <Pricing />
      <Blog /> */}
      <CTA />
      <Contact />
      <Footer />
      <FooterAlt />
      <BackToTop />
    </React.Fragment>
  );
};

export default Layout;
