import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Fade } from 'react-awesome-reveal';

const ServicesCard = (props) => {
  return (
    <>
      {/* <Col lg={4} md={6}> */}
      <Row>
        <div
          className={
            props.isShadow
              ? 'service-box text-center mt-4 box-shadow'
              : 'service-box text-center mt-4'
          }
        >
          <img src={props.imgsrc} alt='' className='img-fluid' />
          <h1 className='text-primary mt-4'>{props.title}</h1>
          <div className='lighlight-border mt-3'></div>
          <p className='text-light mt-3'>{props.caption}</p>
          {props.children.map((value, idx) => (
            <Col key={idx}>
              <div className='mt-4'>
                <h2>{value.title}</h2>
                <img src={value.img} alt='' className='img-fluid' />
                <h5>{value.description}</h5>
                <h6>{value.warranty}</h6>
                <p className='text-light'>{value.caption}</p>
              </div>
            </Col>
          ))}
        </div>
      </Row>

      {/* <!--end col--> */}
    </>
  );
};

// Services data

const ServicesData = [
  {
    title: 'Window Tint',
    caption:
      'You probably know it’s important to protect your eyes on a sunny day. But the sun doesn’t have to be shining for harmful ultraviolet (UV) rays to potentially damage your eyes and the skin around them. Don’t let the clouds fool you; UV rays can reflect off of water, snow, and buildings, even on the most overcast day.',
    children: [
      {
        title: 'CarbonFX Series',
        description: 'Black NR 2-Ply Window Film',
        warranty: 'Premium Lifetime Warranty',
        img: 'https://solarfxwindowfilms.com/wp-content/uploads/2020/01/CarbonFX-Series.png',
        caption:
          'The CarbonFX Series is a True Color-Stable film with a rich, black tone. CarbonFX utilizes the most advanced Dyes on the market. The Deep-Dyed, 2-ply, 1.5Mil polyester film adds in multiple stages of UV Inhibitors during the manufacturing process to insure your film maintains its rich color as the day it is installed while blocking 99% of UVA and UVB rays. This is the film of all Dyed films and carries our Premium lifetime warranty against excessive color change',
      },
      {
        title: 'FXtreme2 Series',
        description: 'Nano-Ceramic 2-Ply Window Film',
        warranty: 'Premium Lifetime Warranty',
        img: 'https://solarfxwindowfilms.com/wp-content/uploads/2020/01/FXtreme2-Series.png',
        caption:
          'The FXtreme2 Nano-Ceramic series uses a proprietary, non-metalized, ceramic material that is specifically designed to reject heat, up to 88% of IR and block 99% of the harmful UVA and UVB rays. This cutting edge technology is built into the 2-ply, 2.0Mil Color-Stable polyester. This automotive window film excels at keeping vehicles cooler, reducing the damaging effects of the sun on the interior, and will not interfere with today’s electronics. FXtreme2 carries our Premium lifetime warranty against excessive color change.',
      },
      {
        title: 'FX Plus Series',
        description: 'Nano-Ceramic 2-Ply Window Film',
        warranty: 'Premium Lifetime Warranty',
        img: 'https://solarfxwindowfilms.com/wp-content/uploads/2020/01/fx-plus-1.png',
        caption:
          'FX PLUS Series is the Ultimate Nano-Ceramic Window Film for Maximum Heat Rejection and Performance! This Series is designed to be Ultimate window film for High IR Rejection (96%-98%) and Total Solar Energy Rejection (TSER). Not only does it provide Maximum Heat Rejection, blocks 99% of harmful UVA/UVB Rays and keeps vehicles cooler, it also reduces the damaging effects of the sun on interiors. Color stable film with Rich, black tone, great optical clarity with no distortion, unmatched scratch-resistant coating, 99% Ultraviolet A/B Rejection and Premium Lifetime Warranty.',
      },
    ],
    isShadow: true,
  },
  {
    title: "Ceramic Coating",
    caption: "At Equinox Tinting, we offer a premium ceramic coating service that protects and enhances your vehicle's exterior. Our advanced coating creates a durable, hydrophobic layer that repels water, dirt, and contaminants, making cleaning easier. It also provides long-lasting protection against UV rays, oxidation, and minor scratches, while enhancing your vehicle's gloss and depth.\nTrust our expert technicians to deliver a flawless finish and maintain your vehicle's showroom shine. Experience the ultimate protection and aesthetics with Equinox Tinting's ceramic coating service.",
    isShadow: true,
    children: []
  }
  // {
  //   imgsrc: img02,
  //   title: 'PPF - Paint Protection Film (COMING SOON)',
  //   caption:
  //     'We understand: no matter how small, that first little nick hurts, and keeping an eye out for everyday road damage is the worst. Why not enjoy driving more, and worrying less by adding a layer of PPF?',
  // },
  // {
  //   imgsrc: img03,
  //   title: 'Ceramic Coating (COMING SOON)',
  //   caption:
  //     'If you have a long commute every day in your car, worry about wear and tear and want to keep the paint looking shiny and new, then a high-level performance coating package would be a great option. The pricing will vary based on the use of your vehicle and what you are trying to get out of the product and for how long.',
  //   isShadow: true,
  // },
];

const Services = () => {
  return (
    <>
      <section
        className='section'
        id='services'
        style={{ backgroundColor: '#181818' }}
      >
        <Container>
          <Row className='justify-content-center'>
            <Col lg={7}>
              <div className='header-title text-center'>
                {/* <p className='text-uppercase text-light mb-2'>
                  Services We Provide
                </p> */}
                <h1>Specializing in many services to improve your ride!</h1>
                <div className='title-border mt-3'></div>
                <p className='text-light mt-3'>
                  We offer a variety of services and products that protect the
                  surfaces of your car from the intense Florida heat, UV rays,
                  wear and tear and more. Our products are specifically designed
                  to keep your car looking its best while being gentle on the
                  paint and glass. We are passionate about providing our
                  customers with the best car care experience possible, offering
                  custom solutions tailored to meet their individual needs.
                </p>
              </div>
            </Col>
          </Row>
          <Fade duration={1200}>
            {/* <Row> */}
            {/* Services Component Start */}

            {ServicesData.map((value, idx) => (
              <ServicesCard
                key={idx}
                // imgsrc={value.imgsrc}
                title={value.title}
                caption={value.caption}
                isShadow={value.isShadow}
                children={value.children}
              />
            ))}

            {/* Services Component End */}
            {/* </Row> */}
          </Fade>
        </Container>
      </section>
    </>
  );
};

export default Services;
