import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

//Import Images
import img15 from "../assets/images/portfolio/img-15.jpg";
import img16 from "../assets/images/portfolio/img-16.jpg";
import img17 from "../assets/images/portfolio/img-17.jpg";
import img18 from "../assets/images/portfolio/img-18.jpg";
import img1 from '../assets/images/portfolio/img-1.jpg';
import img2 from '../assets/images/portfolio/img-2.jpg';
import img3 from '../assets/images/portfolio/img-3.jpg';
import img4 from '../assets/images/portfolio/img-4.jpg';
import img5 from '../assets/images/portfolio/img-5.jpg';
import img6 from '../assets/images/portfolio/img-6.jpg';
import img7 from '../assets/images/portfolio/img-7.jpg';
import img8 from '../assets/images/portfolio/img-8.jpg';
import img9 from '../assets/images/portfolio/img-9.jpg';
import img10 from '../assets/images/portfolio/img-10.jpg';
import img11 from '../assets/images/portfolio/img-11.jpg';
import img12 from '../assets/images/portfolio/img-12.jpg';
import img13 from '../assets/images/portfolio/img-13.jpg';
import img14 from '../assets/images/portfolio/img-14.jpg';

const GalleryData = [
  {
    image: img15,
    title: null,
    category: ['All', 'Tint'],
  },
  {
    image: img16,
    title: null,
    category: ['All', 'Tint'],
  },
  {
    image: img17,
    title: "2023 Lexus RX350",
    category: ['All', 'Tint'],
  },
  {
    image: img18,
    title: null,
    category: ['All', 'Tint'],
  },
  {
    image: img1,
    title: 'Equinox Tinting Storefront',
    category: ['All'],
  },
  {
    image: img2,
    title: 'Equinox Tinting',
    category: ['All'],
  },
  {
    image: img3,
    title: null,
    category: ['All', 'Tint'],
  },
  {
    image: img4,
    title: null,
    category: ['All', 'Tint'],
  },
  {
    image: img5,
    title: null,
    category: ['All', 'Tint'],
  },
  {
    image: img6,
    title: null,
    category: ['All', 'Tint'],
  },
  {
    image: img7,
    title: null,
    category: ['All', 'Tint'],
  },
  {
    image: img8,
    title: null,
    category: ['All', 'Tint'],
  },
  {
    image: img9,
    title: null,
    category: ['All', 'Tint'],
  },
  {
    image: img10,
    title: null,
    category: ['All', 'Tint'],
  },
  {
    image: img11,
    title: null,
    category: ['All', 'Tint'],
  },
  {
    image: img12,
    title: null,
    category: ['All', 'Tint'],
  },
  {
    image: img13,
    title: null,
    category: ['All', 'Tint'],
  },
  {
    image: img14,
    title: null,
    category: ['All', 'Tint'],
  },
];

const Gallery = () => {
  const [displayCategory, setCategory] = useState('All');
  const [photoIndex, setphotoIndex] = useState(0);
  const [isOpen, setisOpen] = useState(false);
  const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img12, img13, img14];

  return (
    <React.Fragment>
      <section className='section portfolio' id='portfolio'>
        <Container fluid={true}>
          <Row className='justify-content-center'>
            <Col lg={7}>
              <div className='header-title text-center'>
                {/* <p className='text-uppercase text-light mb-2'>Portfolio</p> */}
                <h1>Our Work</h1>
                <div className='title-border mt-3'></div>
                <p className='title-desc text-light mt-3'>
                  Our automotive window tinting service enhances the appearance
                  of your car while offering protection from harmful UV rays,
                  reducing heat and glare inside the car, and improving the
                  overall driving experience. Our commercial and residential
                  film options offer privacy, security, and energy efficiency
                  benefits, making your space comfortable and cost-effective.
                </p>
              </div>
            </Col>
          </Row>
          <Row className='my-4 pt-2'>
            <Col lg={12}>
              <div className='filters-group'>
                <ul className='nav filter-options list-unstyled list-inline justify-content-center'>
                  <button
                    className={
                      displayCategory === 'All'
                        ? 'nav-link list-inline-item mt-2 active'
                        : 'nav-link list-inline-item mt-2'
                    }
                    onClick={() => setCategory('All')}
                  >
                    <li
                    // className={
                    //   displayCategory === 'All'
                    //     ? 'nav-link list-inline-item mt-2 active'
                    //     : 'nav-link list-inline-item mt-2'
                    // }
                    >
                      All
                    </li>
                  </button>
                  <button
                    className={
                      displayCategory === 'Tint'
                        ? 'nav-link list-inline-item mt-2 active'
                        : 'nav-link list-inline-item mt-2'
                    }
                    onClick={() => setCategory('Tint')}
                  >
                    <li>Tint</li>
                  </button>
                  {/* <button
                    className={
                      displayCategory === 'PPF'
                        ? 'nav-link list-inline-item mt-2 active'
                        : 'nav-link list-inline-item mt-2'
                    }
                    onClick={() => setCategory('PPF')}
                  >
                    <li>PPF</li>
                  </button>
                  <button
                    disabled={true}
                    className={
                      displayCategory === 'Ceramic'
                        ? 'nav-link list-inline-item mt-2 active'
                        : 'nav-link list-inline-item mt-2'
                    }
                    onClick={() => setCategory('Ceramic')}
                  >
                    <li>Ceramic</li>
                  </button> */}
                </ul>
              </div>
            </Col>
          </Row>
        </Container>

        <Container className='mt-5' fluid={true}>
          <Row className='g-2' id='grid'>
            {GalleryData.filter(
              ({ category }) =>
                category.includes(displayCategory) || displayCategory === 'All'
            ).map(({ title, image, category }, key) => (
              <Col
                key={key}
                className='picture-item'
                data-groups={category}
                md={6}
                lg={3}
              >
                <div className='portfolio-box rounded'>
                  <img className='img-fluid' src={image} alt='work-img' />
                  <div className='portfolio-content'>
                    <div className='img-view'>
                      <i
                        onClick={() => {
                          setphotoIndex(key);
                          setisOpen(!isOpen);
                          console.log(key);
                        }}
                        className='mdi mdi-plus text-light image-popup'
                      ></i>
                      <div className='gallery-overlay'></div>
                    </div>
                    <div className='portfolio-caption'>
                      <Link to='/#' className='text-primary'>
                        <h5 className='mb-1 fs-18'>{title ?? "Equinox Tinting"}</h5>
                      </Link>
                      <p className='mb-0'>{category.join(', ')}</p>
                    </div>
                  </div>
                </div>
                {/* <!--end portfolio-box--> */}
              </Col>
            ))}

            {/* lightbox portfolio images */}

            {isOpen && (
              <Lightbox
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={
                  images[(photoIndex + images.length - 1) % images.length]
                }
                onCloseRequest={() => setisOpen(false)}
                onMovePrevRequest={() =>
                  setphotoIndex(
                    (photoIndex + images.length - 1) % images.length
                  )
                }
                onMoveNextRequest={() =>
                  setphotoIndex((photoIndex + 1) % images.length)
                }
              />
            )}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default Gallery;
