import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';

const CTA = () => {
  return (
    <React.Fragment>
      <section className='bg-cta'>
        <Container>
          <Row className='justify-content-center'>
            <Col lg={10}>
              <div className='header-title text-center'>
                <h2 style={{ color: '#fff' }}>
                  READY TO GET STARTED? CALL US TODAY!
                </h2>
                {/* <p className='title-desc text-light mt-3'>
                  Any car, any time, let us make your car tinting experience
                  memorable
                </p> */}
                {/* <div className='mt-4'>
                  <Button color='white' href='/#' className='mt-2'>
                    Call Now
                  </Button>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default CTA;
